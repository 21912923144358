.content {
  .logo-animation {
    position: absolute;
  }
  .navigation {
    height: calc(100vh - 360px); //fallback
    height: calc(var(--vh, 1vh) * 100 - 360px);
    @media screen and (max-height: 720px) {
      height: auto;
    }
    nav {
      z-index: 1;
      position: relative;
      span {
        display: block;
        overflow: hidden;
        span {
          a {
            display: block;
            text-align: center;
            font-size: 2rem;
            padding: 15px 0;
            @include media("<=tablet") {
              font-size: 1.6rem;
            }
          }
        }

        &:nth-child(2){
          span{
            padding: 0 15px;
            a{
              color: $dark2;
              background: $color;
              animation: action-key infinite 3s linear;
            }
            &::after{
              content: "new";
              background: $white;
              position: absolute;
              width: 45px;
              height: 45px;
              line-height: 45px;
              text-align: center;
              top: 45px;
              right: -8px;
              left: auto;
              padding: 0;
              font-size: 14px;
              font-weight: 700;
              border-radius: 100%;
              color: $dark;
              animation: action-key2 3s linear;
            }
          }
        }
      }
    }
    .menu-background {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: absolute;
      width: calc(100% - 120px);
      height: calc(100vh - 360px);
      height: calc(var(--vh, 1vh) * 100 - 360px);
      .menu-image {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}

@keyframes action-key2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.smash-idzuo{
  position: relative;
  display: block;
  text-align: center;
  font-size: 2rem;
  padding: 15px 0;
  background: $color;
  color: $dark;
  font-weight: 700;
  //animation: action-key infinite 3s linear;
}
.smash-idzuo::after{
  content:"NEW";
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: absolute;
  top: -30px; 
  right: -30px;
  background: rgb(255, 255, 255);
  border-radius: 100%;
  font-weight: bold;
  font-size: 18px;
  box-shadow: -5px 5px 20px 10px #00000040;
  animation: action-key infinite 2s linear;
}
.offer-menu{
  display: block;
  text-align: center;
  font-size: 2rem;
  padding: 15px 0;
  color: $color;
  animation: none;
}
.offer-menu:hover{
  color: $white;
}