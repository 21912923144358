.footer {
  height: 180px;
  padding: 0 60px;
  @include media("<=tablet") {
    height: auto;
    padding: 30px 0;
  }
  div {
    @include media("<=tablet") {
      flex-direction: column;
    }
    .street {
      flex-basis: 33.3%;
      text-align: left;
      @include media("<=tablet") {
        text-align: center;
      }
      span {
        margin: 0 10px;
        text-indent: 0;
        letter-spacing: 1px;
        @include media("<=1160px") {
          font-size: 0.9rem;
        }
      }
    }
    .logo {
      flex-basis: 33.3%;
      text-align: center;
      @include media("<=tablet") {
        margin: 30px 0;
      }
      svg {
        width: 60px;
        height: auto;
      }
    }
    .socials {
      flex-basis: 33.3%;
      text-align: right;
      @include media("<=tablet") {
        text-align: center;
      }
      a {
        margin: 0 10px;
        @include media("<=1160px") {
          font-size: 0.9rem;
          display: block;
          margin: 10px 0 !important;
        }
        &:last-child {
          margin: 0 0 0 10px;
        }
      }
    }
  }
}
