form {
  div {
    &:last-child {
      width: 100%;
      float: none;
    }
  }
}
input[type="date"],
input[type="time"] {
  padding: 0;
  position: relative;
  height: 56px !important;
}
::-webkit-datetime-edit-fields-wrapper {
  padding: 20px 0;
  width: 100%;
  box-sizing: border-box;
}
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-year-field {
  padding: 2px;
}
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field {
  padding-left: 20px;
}
::-webkit-datetime-edit-fields-wrapper:focus,
::-webkit-datetime-edit-text:focus,
::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-year-field:focus,
::-webkit-inner-spin-button:focus,
::-webkit-datetime-edit-hour-field:focus,
::-webkit-datetime-edit-minute-field:focus,
::-webkit-datetime-edit-ampm-field:focus {
  background: none;
  color: $text !important;
}
::-webkit-calendar-picker-indicator,
::-webkit-time-picker-indicator {
  width: 100%;
  color: #fff;
  background: none;
  position: absolute;
  padding: 20px 0;
  cursor: pointer;
  margin: 0;
}
