.cards {
  div {
    flex-direction: row;
    @include media("<=480px") {
      flex-direction: column;
      align-items: center;
    }
    svg {
      width: 15%;
      @include media("<=480px") {
        width: 30%;
        margin: 10px 0;
      }
    }
  }
}
