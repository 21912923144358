html{
  scroll-behavior: smooth;
}

.header {
  height: 180px;
  z-index: 5;
  padding: 0 60px;
  @include media("<=tablet") {
    height: auto;
    padding: 30px 0;
  }
  div {
    @include media("<=tablet") {
      flex-direction: column;
    }
    .logo {
      flex-basis: 33.3%;
      text-align: left;
      a {
        font-size: 2em;
        font-weight: 700;
        &:hover {
          color: $white;
        }
      }
    }
    .time {
      flex-basis: 33.3%;
      text-align: center;
      @include media("<=tablet") {
        margin: 15px 0;
      }
    }
    .phone {
      flex-basis: 33.3%;
      text-align: right;
      @include media("<=tablet") {
        text-align: center;
      }
      a {
        @include media("<=1160px") {
          display: block;
          margin: 10px auto;
        }
        span {
          @include media("<=1160px") {
            margin: 0 auto;
          }
          &:last-child {
            margin: 0 0 0 10px;
            @include media("<=768px") {
              margin: 0 10px;
            }
          }
        }
      }
    }
    span {
      letter-spacing: 1px;
      margin: 5px 10px;
      display: inline-block;
      @include media("<=1160px") {
        font-size: 0.9rem;
        display: block;
        margin: 10px 0;
      }
      @include media("<=tablet") {
        display: block;
        margin: 10px 0;
      }
    }
  }
}

/*ACTION*/
#action-1,
#action-2,
#action-3{
  padding-bottom: 10px;
  border-bottom: 1px solid $color;
}
.action-date{
  text-align: center;
}
.action {
  display: flex;
  grid-gap: 15px;
  justify-content: center;
  animation: action-key infinite 3s linear;
  z-index: 1;
  margin: 0 auto 90px auto;
  align-items: stretch;
  @include media("<=640px") {
    flex-wrap: wrap;
  }
  div{
    background: $color;
    padding: 30px;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: relative;
    .action-link{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      text-indent: -9999px;
    }
    h4{
      margin: 0 auto 15px auto;
      color: $dark2;
      font-size: 21px;
      font-weight: 700;
    }
    p{
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 0 auto;
      padding: 0;
      color: $dark2;
      line-height: 1rem;
      span{
        font-size: 18px;
        font-weight: 700;
        &.old{
          position: relative;
          opacity: 0.5;
          margin: 0 10px;
          &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 3px;
            background: $dark2;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0.8;
          }
        }
      }
    }
  }
}

@keyframes action-key {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
