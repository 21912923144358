.content-text {
  text-align: justify;
}

.swiper-container {
  width: 100%;
  height: 350px;
  margin: 0 auto 120px auto;
  @include media("<=1440px") {
    height: 450px;
  }
  .swiper-slide {
    overflow: hidden;
    .slide-img {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }
}
.player-wrapper {
  margin: 0 auto 180px auto;
  @include media("<=768px") {
    margin: 0 auto 90px auto;
  }
}
.react-player__preview {
  transition: 0.3s ease-in-out;
  &:hover {
    animation-name: scaleinout;
    animation-duration: 0.6s;
    animation-iteration-count: 1;
    animation-direction: alternate;
    animation-timing-function: 0.6, 0.01, -0.05, 0.9;
    animation-fill-mode: forwards;
  }
}
@keyframes scaleinout {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}
.player-wrapper,
.react-player__preview,
.player-wrapper video {
  outline: none;
}
.video-play {
  cursor: pointer;
  background: $dark;
  border: none;
  width: 70px;
  height: 70px;
  margin: 0;
  overflow: hidden;
  border-radius: 100%;
  transition: 0.3s ease-in-out;
  outline: none;
  svg {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-top: 2px;
  }
  &:hover {
    background: $color;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
  &:after {
    font-size: 24px !important;
  }
}

ul.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @include media("<=768px") {
    flex-direction: column;
  }
  li a {
    @include media("<=768px") {
      margin: 15px 0;
    }
  }
}
