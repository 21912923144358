@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import "./fonts/stylesheet.css";
@import "./variables.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/footer.scss";
@import "./pages/home.scss";
@import "./pages/about.scss";
@import "./pages/order.scss";
@import "./pages/reservations.scss";
@import "./pages/menu.scss";

* {
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
form {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html,
body {
  visibility: hidden;
  color: $text;
  background: $dark;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  user-select: none;
  overscroll-behavior: none;
  margin: 0;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button {
  font-weight: 500;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
input,
textarea {
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $white;
}
h4 {
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  margin: 0 auto 30px auto;
  @include media("<=768px") {
    text-align: center;
  }
}
//text
p {
  color: $text;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 2.4rem;
  margin: 0 auto 15px auto;
  @include media("<=768px") {
    text-align: center;
  }
}
p:last-child {
  margin: 0 auto;
}
.p-2 {
  font-size: 1.2rem;
  line-height: 2.5rem;
  letter-spacing: 0.05rem;
  @include media("<=768px") {
    text-align: center;
  }
}
.p-3 {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @include media("<=768px") {
    text-align: center;
  }
}
.justify {
  text-align: justify;
}
//colors
.white {
  color: $white;
}
.dark {
  color: $dark;
}
//block
.flex {
  display: flex;
  @include media("<=768px") {
    flex-direction: column;
  }
}
.flex-end {
  align-items: flex-end;
  @include media("<=768px") {
    align-items: center;
  }
}
.row {
  display: flex;
  height: 100%;
}
.v-center {
  align-items: center;
}
.h-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.margin-right-90{
  margin-right: 90px;
}
@media (max-width: 768px) {
  .margin-right-90{
    margin-right: 0;
  }
}
//Link
a {
  color: #fff;
  text-decoration: none;
  outline: none;
  transition: 0.3s ease-in-out;
  &:hover {
    color: $color;
  }
}
//Link fancy hover
.fancy-link {
  display: inline-block;
  overflow: hidden;
  letter-spacing: 1px;
  span {
    position: relative;
    display: inline-block;
    transition: transform 0.3s;
    &::before {
      position: absolute;
      top: 100%;
      content: attr(data-hover);
      transform: translate3d(0, 0, 0);
    }
  }
  &:hover span,
  &:focus span {
    transform: translateY(-100%);
  }
}
//Link center
.link-center {
  @include media("<=768px") {
    display: block;
    margin: 0 auto;
  }
}
//List
ul {
  padding: 0;
  margin: 0;
}
li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.main {
  padding: 0;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}
//Image full width
.width-100 {
  width: 100%;
  @include media("<=768px") {
    width: auto;
    height: 150%;
  }
}
//Content
.content {
  overflow: hidden;
}
.content-in {
  margin: 0 auto;
  padding: 120px 0;
  width: 1200px;
  max-width: 1200px;
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  @include media("<=desktop") {
    width: 100%;
    max-width: 100%;
    padding: 120px 30px;
  }
  @include media("<=980px") {
    width: 100%;
    max-width: 100%;
    padding: 120px 30px;
  }
  @include media("<=768px") {
    padding: 90px 30px;
  }
}
.content-block {
  width: 768px;
  max-width: 768px;
  margin: 0 auto 120px auto;
  @include media("<=tablet") {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 90px auto;
  }
  &:last-child {
    margin: 0 auto;
  }
}
.width-980 {
  width: 780px;
  max-width: 780px;
  @include media("<=768px") {
    width: 100%;
    max-width: 100%;
  }
}
.has-bottom-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.content-img-50 {
  overflow: hidden;
  margin: 0 auto 120px auto;
  @include media("<=768px") {
    margin: 0 auto 90px auto;
  }
  .content-img-inner {
    position: relative;
    overflow: hidden;
    margin: 0 30px 0 0;
    @include media("<=768px") {
      margin: 0 auto 90px auto;
    }
    &:last-child {
      margin: 0 auto 0 30px;
      @include media("<=768px") {
        margin: 0 auto;
      }
    }
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
    }
  }
}
.content-img-inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 240px;
  max-height: 240px;
  margin: 0 auto 120px auto;
  .content-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
//Map
.map {
  svg {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 50%;
    top: 32%;
    z-index: 1;
  }
  &:hover svg {
    animation: jump 0.4s 1 alternate forwards;
  }
}

@keyframes jump {
  0% {
    top: 32%;
  }
  50% {
    top: 30%;
  }
  100% {
    top: 32%;
  }
}
//Hero image
.hero-section {
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}
.hero-back {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 1px;
  @include media("<=420px") {
    left: 10px !important;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: auto;
      height: 24px;
      path {
        stroke: $white;
      }
    }
    &:hover {
      color: #fff;
    }
  }
  &:hover a {
    animation: fadeInLeft 0.4s 1 alternate forwards;
  }
}
@keyframes fadeInLeft {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -5px;
  }
  100% {
    margin-left: 0;
  }
}
.hero-title {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  position: absolute;
  z-index: 1;
}
.hero-img-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: calc(100% - 120px);
  height: calc(100vh - 360px);
  height: calc(var(--vh, 1vh) * 100 - 360px);
  img {
    width: 100%;
    height: auto;
    @include media("<=1550px") {
      width: auto;
      height: auto;
    }
  }
}
//Form
//Alert message
.notice {
  position: fixed;
  width: 350px;
  height: auto;
  padding: 30px;
  background: $color;
  text-align: center;
  line-height: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2rem;
  color: $dark;
  letter-spacing: 0.05rem;
  transition: opacity 0.5s;
  opacity: 0;
  pointer-events: none;
}
form {
  overflow: hidden;
  margin: 0 -15px;
  div {
    padding: 0 15px 30px 15px;
    width: 50%;
    float: left;
    box-sizing: border-box;
    overflow: hidden;
    @include media("<=580px") {
      width: 100% !important;
      float: none !important;
    }
    input,
    textarea {
      color: $text;
      width: 100%;
      height: auto;
      padding: 20px;
      border: none;
      background: rgba(255, 255, 255, 0.05);
      outline: none;
      box-sizing: border-box;
      text-transform: uppercase;
      font-weight: 600;
    }
    .form-button {
      width: 100%;
      padding: 20px 15px;
      text-transform: uppercase;
      font-weight: 500;
      background: rgba(255, 255, 255, 0.05);
      border: none;
      height: auto;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.05rem;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      outline: none;
      &:hover {
        background: $white;
        color: $dark;
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
