.list-menu {
  margin-bottom: 60px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  box-sizing: border-box;
  @include media("<=phone") {
    grid-template-columns: 100%;
  }
  li {
    display: flex;
    gap: 15px;
    padding: 15px;
    transition: all 0.2s ease-in-out;
    background: rgb(29, 29, 29);
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    &:hover {
      background: rgb(48, 48, 48);
    }
    @include media("<=phone") {
      flex-direction: column;
    }
    .list-menu-info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .list-menu-top{
        display: flex;
        flex-direction: column;
      }
    }
    &.barbecue:last-child::after,
    &.sides:nth-child(5)::after,
    &.sides:nth-child(6)::after{
      content: "novo";
      background: $white;
      position: absolute;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      top: 6px;
      left: 6px;
      padding: 0;
      font-size: 14px;
      font-weight: 700;
      border-radius: 100%;
      color: $dark;
    }
  }
}
.list-img {
  width: 40%;
  height: auto;
  @include media("<=phone") {
    width: 100%;
  }
}
.list-title {
  color: $white;
  line-height: 1.6rem;
  font-size: 1rem; 
  letter-spacing: 1px;
  width: 100%;
}
.list-desc {
  color: $text;
  text-transform: initial;
  letter-spacing: 0;
  font-size: 1.15rem;
}
.list-price {
  font-size: 1rem;
  color: #fff;
  margin-top: 15px;
}
.productOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(15, 16, 16, 0.9);
  z-index: 1;
}
.productModal {
  width: 640px;
  max-width: 640px;
  height: auto;
  overflow: hidden;
  background: $dark2;
  flex-direction: column;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  @include media("<=780px") {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
  }
  .product-img-holder {
    height: 360px;
    display: block;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      @include media("<=500px") {
        width: 150%;
      }
    }
  }
  .product-details {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: auto;
    align-items: flex-start;
    padding: 60px;
    @include media("<=768px") {
      padding: 0 30px;
      overflow: scroll;
      margin: 60px 0;
    }
    .list-title {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .list-desc {
      margin-top: 15px;
      line-height: 1.6rem;
      grid-column-start: 1;
      grid-column-end: 6;
      padding: 15px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      &:empty {
        padding: 0;
        border-bottom: none;
      }
    }
    .list-price{
      margin-top: 0;
      text-align: right;
    }
    .list-extra {
      display: grid;
      grid-template-columns: 33.3% 33.3% 33.3%;
      grid-template-rows: auto;
      padding: 20px 0 30px 0;
      grid-column-start: 1;
      grid-column-end: 6;
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      align-items: start;
      .list-title {
        padding-bottom: 20px;
      }
      .check {
        position: relative;
        transition: all 0.2s ease-in-out;
        &.white {
          @include media("<=420px") {
            padding: 20px 0;
          }
        }
        @include media("<=420px") {
          grid-column-start: 1;
          grid-column-end: 4;
        }
        &:hover {
          padding-left: 5px;
        }
      }
      input[type="checkbox"] {
        position: absolute;
        //visibility: hidden;
        opacity: 1;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
      }
      label {
        cursor: pointer;
        padding-left: 35px;
        color: $text;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        @include media("<=560px") {
          align-items: flex-start;
        }
        &::before {
          content: "";
          left: 0;
          width: 15px;
          height: 15px;
          position: absolute;
          background: none;
          border: 2px solid rgba(255, 255, 255, 0.2);
          transition: all 0.1s ease-in-out;
        }
        &::after {
          display: block;
          top: 0 !important;
          left: 5px !important;
          content: "";
          position: absolute;
          width: 7px;
          height: 12px;
          border-color: #b92d2d;
          border-style: solid;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg) scale(0);
          transition: all 0.2s ease-in-out;
        }
      }
    }
    .list-extra.white {
      grid-column-start: 4;
      grid-column-end: 6;
    }
    .list-cart {
      margin-top: 45px;
      grid-column-start: 1;
      grid-column-end: 7;
      button {
        cursor: pointer;
        grid-column-start: 1;
        grid-column-end: 7;
        background: none;
        border: none;
        color: $color;
        text-transform: uppercase;
        span {
          padding: 2px 0;
        }
      }
    }
  }
}
//Close button
.close {
  width: 30px;
  height: 30px;
  background: $dark;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  svg {
    width: 14px;
  }
  @include media("<=780px") {
    top: 25px;
    right: 30px;
  }
  img{
    width: 14px;
  }     
}
.cart {
  .close {
    top: 55px;
    right: 60px;
    background: $color;
    svg {
      .st0-cart {
        fill: $dark;
      }
    }
    @include media("<=780px") {
      top: 25px;
      right: 30px;
    }
  }
  form {
    margin: 0 auto;
    max-height: calc(100vh - 360px);
    overflow-y: scroll;
    @include media("<=780px") {
      max-height: none;
    }
  }
}

//Shopping Cart
//button
.shopping-cart-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  padding: 10px;
  background: $dark2;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-right: none;
  z-index: 2;
  @include media("<=780px") {
    top: auto;
    bottom: 0;
    transform: none;
  }
  svg {
    width: 65%;
  }
  &:focus {
    outline: none;
  }
  .cart-notice {
    width: 20px;
    height: 20px;
    background: $color;
    border-radius: 100%;
    left: -10px;
    top: 0;
    position: absolute;
    line-height: 21px;
    font-weight: 600;
  }
}
//Cart modal
.cart {
  width: 840px;
  min-width: 650px;
  position: fixed;
  left: 50%;
  top: 49%;
  background: $dark2;
  transform: translate(-49%, -50%);
  transition: all 0.3s ease-in-out;
  padding: 60px;
  box-sizing: border-box;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  overflow-y: scroll;
  &.open {
    top: 50%;
    opacity: 1;
    visibility: visible;
  }
  @include media("<=780px") {
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transform: none;
    width: 100%;
    min-width: auto;
    padding: 30px;
    &.open {
      top: 0;
    }
  }
  //title
  .shpping-cart-title {
    color: $white;
    margin-bottom: 30px;
    font-size: 1.2rem;
    letter-spacing: 0.04rem;
    &.empty {
      margin-bottom: 0;
      text-align: left;
    }
  }
  //table
  .cart-row {
    display: grid;
    width: 100%;
    grid-template-columns: 50% 25% 25%;
    grid-template-rows: auto;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    &:last-child {
      border: none;
    }
    &.min-span {
      display: block;
      text-align: right;
      padding-top: 30px;
      font-style: italic;
    }
    //table title
    .cart-title {
      font-size: 0.95rem;
      color: #fff;
      line-height: 1.4rem;
      letter-spacing: 0.05rem;
      &.shipping,
      &.total {
        font-weight: 600;
      }
    }
    //table plus, minus
    .cart-buttons {
      display: flex;
      justify-content: center;
      align-items: center;
      .cart-buttons-qty {
        width: 30px;
        background: none;
        color: $white;
        text-align: center;
      }
      span {
        width: 20px;
        height: 20px;
        align-self: center;
        background: none;
        margin: 0;
        cursor: pointer;
        padding: 2px;
        background: rgba(255, 255, 255, 0.1);
        border: none;
        transition: all 0.1s ease-in-out;
        box-sizing: border-box;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
        &:focus {
          outline: none;
        }
      }
    }
    //table price
    .cart-item-price,
    .cart-price {
      color: $white;
      text-align: right;
      letter-spacing: 0.05rem;
      span {
        text-transform: none;
      }
    }
    .cart-price {
      grid-column-start: 3;
      grid-column-end: 3;
    }
    //table button
    .cart-checkout {
      border: none;
      background: none;
      color: $color;
      text-transform: uppercase;
      cursor: pointer;
      grid-column-start: 2;
      grid-column-end: 4;
      text-align: right;
      margin-top: 15px;
      &:focus {
        outline: none;
      }
    }
  }
  .cart-inputs {
    width: auto;
    padding: 0;
    margin: 0 -10px;
    padding-top: 30px;
    @include media("<=780px") {
      margin: 30px auto 0 auto;
      width: 100%;
    }
    div {
      padding: 0 10px;
      width: 33.3%;
      @include media("<=780px") {
        margin: 10px 0 0 0;
        padding: 0;
        width: 100%;
        float: none;
      }
      &.payment {
        width: 100%;
        padding-top: 45px;
        div {
          display: flex;
          align-items: center;
          padding: 0;
          width: calc(50% - 10px);
          margin-right: 20px;
          background: rgba(255, 255, 255, 0.05);
          padding: 15px 20px;
          position: relative;
          cursor: pointer;
          input {
            width: auto;
            margin-right: 10px;
          }
          label {
            cursor: pointer;
          }
          [type="radio"]:checked,
          [type="radio"]:not(:checked) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
          }
          [type="radio"]:checked + label,
          [type="radio"]:not(:checked) + label {
            position: relative;
            padding-left: 28px;
            line-height: 20px;
          }
          [type="radio"]:checked + label:before,
          [type="radio"]:not(:checked) + label:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: none;
            background: $dark2;
          }
          [type="radio"]:checked + label:after,
          [type="radio"]:not(:checked) + label:after {
            content: "";
            position: absolute;
            top: 3px;
            left: 6px;
            width: 4px;
            height: 8px;
            border-color: $color;
            border-style: solid;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) scale(1);
            transition: all 0.1s ease-in-out;
          }
          [type="radio"]:not(:checked) + label:after {
            opacity: 0;
            transform: rotate(0) scale(0);
          }
          [type="radio"]:checked + label:after {
            opacity: 1;
            transform: rotate(45deg) scale(1);
          }
          &.shpping-cart-title {
            display: block;
            overflow: hidden;
            width: 100%;
            background: none;
            padding: 0;
            margin: 0 auto 30px auto;
          }
        }
      }
      &.notice-msg {
        width: 100%;
        padding-top: 45px;
      }
    }
  }
}
.input-invisible {
  display: none;
}

/* Offer */
.list-menu.offer{
  grid-template-columns: 100%;
}
.list-menu.offer li{
  display: flex;
  overflow: hidden;
  border: 2px solid $color;
  border-radius: 16px;
}
.list-menu.offer li .list-img {
  width: 20%;
  height: 140px;
  border-radius: 8px;
  @include media("<=930px") {
    width: 40%;
    height: 120px;
  }
  @include media("<=phone") {
    width: 100%;
    height: auto;
  }
}
.list-menu.offer li .list-title{
  font-size: 18px;
}
.list-menu.offer li .list-desc{
  font-size: 18px;
  margin-top: 12px;
}
.list-menu.offer li .list-price{
  font-size: 24px;
}


.breakfast-false{
  display: none!important;
}



/* Smash Burgers */
.list-menu.smash{
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  li{
    flex-direction: column;
    border-radius: 15px;
    padding: 20px;
    .list-img{
      width: 100%;
      border-radius: 10px;
    }
    .list-title{
      font-size: 18px;
      margin-bottom: 6px;
    }
    .list-price{
      font-size: 18px;
    }
  }
  li:nth-child(5),
  li:nth-child(6),
  li:nth-child(9),
  li:nth-child(10){    
    margin-top: 120px;
  }
} 
